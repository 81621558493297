import React from "react";
import { ReactComponent as LogoDarkImg } from "../assets/svg/LogoDark.svg";

function LogoDark() {
  return (
    <>
      <LogoDarkImg />
    </>
  );
}

export default LogoDark;
