import React, { useState } from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import { Slide } from "react-swift-reveal";
import useFetchBlogs from "../utils/useFetchBlogs.js";
import { Link } from "react-router-dom";

function Blog() {
  const { articles, loading, error } = useFetchBlogs();
  const [visibleCount, setVisibleCount] = useState(3);

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching blogs</div>;

  return (
    <>
      <Hero
        heading="News and Heights"
        subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies."
      />
      <div className="blogs blogPage">
        <div className="container">
          <Slide left duration={3000} delay={100}>
            <Heading title="Recent Blog Post" />
          </Slide>

          {articles.length > 0 ? (
            <div className="gridWrapper">
              {articles.map((article) => {
                const articleAttributes = article || {};

                // Featured image handling
                const featuredImage =
                  articleAttributes.featuresImage?.[0]?.formats?.medium?.url ||
                  articleAttributes.featuresImage?.[0]?.formats?.small?.url ||
                  articleAttributes.featuresImage?.[0]?.url ||
                  null;

                // Author image handling
                const authorImageUrl =
                  articleAttributes.authorPic?.formats?.thumbnail?.url ||
                  articleAttributes.authorPic?.url ||
                  null;

                return (
                  <div key={article.id} className="blog">
                    {/* Featured Image */}
                    {featuredImage && (
                      <img
                        src={featuredImage}
                        alt={
                          articleAttributes.featuresImage?.[0]
                            ?.alternativeText || "Featured"
                        }
                        className="featuredImg"
                      />
                    )}

                    {/* Post Header */}
                    <div className="postHead">
                      <div className="nameWrapper">
                        {/* Author Image */}
                        {authorImageUrl && (
                          <img
                            src={authorImageUrl}
                            alt={
                              articleAttributes.authorPic?.alternativeText ||
                              "Author"
                            }
                            className="authorImg"
                          />
                        )}
                        {/* Author Name and Date */}
                        <div className="authorDate">
                          <span>
                            {articleAttributes.author || "Unknown Author"}
                          </span>
                          <span className="date-blog">
                            {new Date(
                              articleAttributes.date
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Article Title and Short Description */}
                    <div className="title">
                      {articleAttributes.Title || "Untitled"}
                    </div>
                    <div className="shortDesc">
                      {articleAttributes.shortDes || "No description available"}
                    </div>

                    {/* Link to Full Article */}
                    <Link
                        to={`/post/${article.id}/${encodeURIComponent(
                          articleAttributes.Title.toLowerCase().replace(/\s+/g, "-")
                        )}`}
                        className="link"
                        onClick={() => console.log("send Blog ID:", article.id)}
                      >
                        Read More
                      </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>No blog posts available</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Blog;
