import React from "react";
import partners from "../utils/data/partners";
import { Zoom } from "react-swift-reveal";
import Heading from "./Heading";
import { Link } from "react-router-dom";

function Partners() {
  return (
    <section className="partners">
      <div className="container">
        <Zoom duration={3000}>
          <Heading title="We are featured internationally" />
        </Zoom>
        <div className="partnerWrapper">
          {partners.map((partner) => (
            <Link key={partner.id} to={partner.url} target="_blank">
              <div className="box">
                <img src={partner.img} alt="" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Partners;
