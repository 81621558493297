import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RequestforDel() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showModal, setShowModal] = useState(false); // State for showing modal

  const baseUrl = process.env.REACT_APP_MYARBIT_HostUrl;

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}user/sendVerificationCodeLandingPage`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-type": "LANDINGPAGE",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Verification code sent to your email", {
          position: "top-center",
        });
      } else {
        toast.error("Failed to send verification code", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error sending verification code",
        { position: "top-center" }
      );
    }
  };

  // Show confirmation modal when user clicks "Submit"
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true); // Show the modal
  };

  // Function to handle user deletion
  const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}user/delete`,
        {
          email: email,
          verificationCode: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-client-type": "LANDINGPAGE",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message || "User deleted successfully", {
          position: "top-center",
        });
      } else {
        toast.error("Failed to delete user", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting user", {
        position: "top-center",
      });
    }
    setShowModal(false); // Close the modal after action
  };

  // Close modal on clicking "No"
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ToastContainer />

      <h1 className="form-heading">Delete User Data</h1>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="otp">Verification Code</label>
            <div className="otp-container">
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <button
                type="button"
                className="send-otp-btn"
                onClick={handleSendOtp}
              >
                Send Code
              </button>
            </div>
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Are you sure?</h2>
            <p>
              Are you sure you want to proceed with deleting your account? This
              action is irreversible and will permanently remove your account
              and associated data. Once deleted, you will not be able to recover
              your account.
            </p>
            <div className="modal-actions">
              <button className="yes-btn" onClick={handleDelete}>
                Yes
              </button>
              <button className="no-btn" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RequestforDel;
