import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import Twitter from "../../assets/images/twitter.png";
import Youtube from "../../assets/images/youtube.png";
import Whatsapp from "../../assets/images/whatsapp.png";
import Telegram from "../../assets/images/telegram.png";

const socialMedia = [
  {
    id: 1,
    name: "Facebook",
    icon: Facebook,
    path: "https://www.facebook.com/myarbit/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
    alt:"Crypto Staking",
  },
  {
    id: 2,
    name: "Instagram",
    icon: Instagram,
    path: "https://www.instagram.com/myarbit/?igshid=MzRlODBiNWFlZA%3D%3D",
    alt:"Staking pools",

  },
  {
    id: 3,
    name: "X",
    icon: Twitter,
    path: "https://twitter.com/my_arbit?t=TrNg-n9hO5qc0tzqMSCCZw",
    alt:"what is crypto staking",

  },
  {
    id: 4,
    name: "Youtube",
    icon: Youtube,
    path: "https://www.youtube.com/@My_Arbit",
    alt:"cryptocurrency staking platforms",

  },
  // {
  //   id: 5,
  //   name: "Whatsapp",
  //   icon: Whatsapp,
  //   path: "https://api.whatsapp.com/send/?phone=971567291949&text&type=phone_number&app_absent=0",
  // },
  {
    id: 5,
    name: "Telegram",
    icon: Telegram,
    path: "https://t.me/myarbit",
    alt:"Exchanges Crypto Arbitrage",

  },
];

export default socialMedia;
