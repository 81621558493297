import React from "react";

function Heading({ title }) {
  return (
    <>
      <div className="heading">{title}</div>
    </>
  );
}

export default Heading;
