import React from "react";
import { ReactComponent as LogoImg } from "../assets/svg/Logo.svg";

function Logo() {
  return (
    <>
      <LogoImg />
    </>
  );
}

export default Logo;
