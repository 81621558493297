import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../components/Hero";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const BlogDetail = () => {
  const [blogDetail, setBlogDetail] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Get the blog ID from the URL

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await fetch(
          "https://artistic-cheese-40e4a612d1.strapiapp.com/api/blogs?populate=*"
        );
        const result = await response.json();

        // Filter the data to find the blog with the specific id
        const blog = result.data.find((blog) => blog.id === parseInt(id));
        if (blog) {
          setBlogDetail(blog);
        } else {
          setError("Blog post not found");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchBlogDetail();
  }, [id]);

  if (error) {
    return <div>Error fetching blog details: {error}</div>;
  }

  if (!blogDetail) {
    return <div>Loading...</div>;
  }

  // Extract image URLs
  const featuredImageUrl =
    blogDetail.featuresImage?.[0]?.formats?.large?.url ||
    blogDetail.featuresImage?.[0]?.url; // Use a fallback if specific format not available
  const authorImageUrl = blogDetail.authorPic?.url;

  const content = blogDetail.content;

  return (
    <>
      <Hero
        heading="News and Heights"
        subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies."
      />
      <div className="blogDetail">
        <div className="container">
          <div className="circle pinkCircle"></div>
          {/* Render featured image */}
          {featuredImageUrl && (
            <img
              src={featuredImageUrl}
              alt="investment platforms"
              className="featuredImg"
            />
          )}

          <div className="postHead">
            <div className="nameWrapper">
              {/* Render author image */}
              {authorImageUrl && (
                <img src={authorImageUrl} alt="author" className="authorImg" />
              )}
              <div className="name">{blogDetail?.author}</div>
              <div className="date">{blogDetail?.date}</div>
            </div>
          </div>

          <div className="content">
            <div className="mainHeading">{blogDetail?.Title}</div>
            <div className="heading">Introduction</div>
            <p>{blogDetail?.shortDes}</p>

            {content ? (
              <BlocksRenderer content={content} />
            ) : (
              <p>No content available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
