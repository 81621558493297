const routes = [
  { id: 1, text: "Home", path: "/" },
  { id: 2, text: "About Us", path: "/about-us" },
  { id: 3, text: "Profit Tiers", path: "/profit-tiers" },
  { id: 4, text: "Blog", path: "/blog" },
  { id: 5, text: "Contact Us", path: "/contact-us" },
  { id: 6, text: "FAQ's", path: "/faq" },
];

export default routes;
