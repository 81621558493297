import React from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "What is the minimum stake required to start investing on MyArbit?",
      content: "The minimum stake required to start investing on MyArbit is 50 USDT.",
    },
    {
      title: "When can I withdraw my funds from MyArbit?",
      content: "You can withdraw funds when you have an available balance of 50 USDT or more.",
    },
    {
      title: "Do I receive my profits daily on MyArbit?",
      content: "Profit distribution depends on the pool you've subscribed to. For example, if you've subscribed to a 10-day pool, you'll receive profits after 10 days, and similarly for other pools.",
    },
    {
      title: "How do I make a deposit on MyArbit?",
      content: "To make a deposit, create an account on our website at myarbit.com, obtain your TRC20 wallet address, and deposit USDT into that TRC20 wallet.",
    },
    {
      title: "What are the withdrawal limits on MyArbit?",
      content: "There is no upper withdrawal limit. You can withdraw a minimum of 50 USDT and a maximum of your available balance.",
    },
    {
      title: "Are there fees for deposits and withdrawals on MyArbit?",
      content: "There are no deposit fees. However, there is a 2USDT withdrawal fee for blockchain network fees.",
    },
    {
      title: "How can I top up my account through SWIFT, Money Gram, or Western Union?",
      content: "You can top up your account using these methods by contacting the support team at our official email or the official WhatsApp number provided on the website.",
    },
    {
      title: "What is MyArbit and how can it benefit me?",
      content:
        "MyArbit is a platform passionate about revolutionizing cryptocurrency investing, specifically through crypto arbitrage. Learn how you can leverage cutting-edge technology and expert insights for financial success.",
    },
    {
      title: "As a Crypto Arbitrage enthusiast, how can I get started on MyArbit?",
      content:
        "Joining MyArbit is simple! Visit our platform, register with your details, and explore the various investment tiers designed to make crypto arbitrage accessible, secure, and rewarding.",
    },
    {
      title: "What sets MyArbit apart in the world of crypto investing?",
      content:
        "MyArbit stands out by combining state-of-the-art technology with expert knowledge, offering unmatched prospects for investors. Our meticulous platform design ensures a seamless journey towards financial prosperity.",
    },
    {
      title: "Can you provide examples of notable career highlights or recognitions for MyArbit?",
      content: "MyArbit has gained recognition from social media influencers, magazines, and success stories in the cryptocurrency space.",
    },
    {
      title: "How does MyArbit overcome challenges in the dynamic crypto market?",
      content: "MyArbit navigates challenges with wisdom, adapting strategies to the ever-changing crypto landscape, ensuring continued growth and success.",
    },
    {
      title: "What wisdom can I gain from MyArbit's educational approach to crypto arbitrage?",
      content: "MyArbit not only focuses on financial gains but also emphasizes intellectual growth. Join our community to broaden your knowledge and skills in the crypto world.",
    },
    {
      title: "Where do you envision MyArbit in the next few years?",
      content: "MyArbit aspires to be a leading force in crypto investing, providing innovative solutions and expanding opportunities for investors globally.",
    },
    {
      title: "How is the commission structured on MyArbit, and is it the same for all brands?",
      content: "MyArbit charges a commission based on the percentage of the net sale. This commission may vary for different brands, offering a flexible and tailored approach.",
    },
    {
      title: "What specific angle or focus should I expect from articles about MyArbit?",
      content: "Articles about MyArbit will likely highlight the platform's commitment to education, accessibility, security, and the rewarding aspects of crypto arbitrage.",
    },
    {
      title: "What is MyArbit's goal with press coverage?",
      content: "MyArbit aims to increase awareness about its revolutionary approach to crypto investing, educate the community, and establish itself as a trusted and innovative platform.",
    },
  ],
};

function FAQ() {
  return (
    <>
      <Hero heading="FAQ’s" subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies." />
      <div className="faq">
        <div className="container">
          <div className="circle pinkCircle"></div>
          <Heading title="Frequently Asked Questions" />
          <div className="subHeading">Here are some common questions about MyArbit</div>
          <Faq data={data} />
        </div>
      </div>
    </>
  );
}

export default FAQ;
