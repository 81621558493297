import React from "react";
import { ReactComponent as LogoImg } from "../assets/svg/FooterLogo.svg";

function FooterLogo() {
  return (
    <>
      <LogoImg />
    </>
  );
}

export default FooterLogo;
