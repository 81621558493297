const benefits = [
  {
    id: 1,
    number: 1,
    title: "Diverse Investment Pools",
    desc: "Choose from a variety of investment pools available on the dashboard. Each pool caters to different risk appetites and investment preferences, providing users with options to align with their financial goals.",
  },
  {
    id: 2,
    number: 2,
    title: "User-Friendly Platform",
    desc: "MyArbit is designed with user convenience in mind. The platform is intuitive and easy to navigate, making it accessible for both beginners and experienced investors.",
  },
  {
    id: 3,
    number: 3,
    title: "Transparent Operations",
    desc: "Transparency is a key principle at MyArbit. Users can track their investments, profits, and transactions in real-time through a clear and comprehensive dashboard, ensuring complete visibility into their financial activities.",
    cards: [
      {
        id: 1,
        title: "Risk-aligned for a peace of mind",
      },
      {
        id: 2,
        title: "Dynamic adaptation",
      },
      {
        id: 3,
        title: "Calm and calculated",
      },
    ],
  },
  {
    id: 4,
    number: 4,
    title: "Referral Program",
    desc: "Engage in the MyArbit community and earn additional bonuses through the referral program. Invite others to join MyArbit, share the benefits of crypto arbitrage, and earn rewards for expanding the user base.",
    cards: [
      {
        id: 1,
        title: "Security you can bank on",
      },
      {
        id: 2,
        title: "Transparency fosters trust",
      },
      {
        id: 3,
        title: "Regulatory compliance",
      },
    ],
  },
];

export default benefits;
