import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
import "./responsive.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import ProfitTiers from "./pages/ProfitTiers";
import FAQ from "./pages/FAQ";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import BlogDetail from "./pages/BlogDetail";
import RequestforDel from "./pages/RequestforDel";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Home />} />
          <Route element={<About />} path="about-us" />
          <Route element={<ProfitTiers />} path="profit-tiers" />
          <Route element={<Contact />} path="contact-us" />
          <Route element={<PrivacyPolicy />} path="privacy-policy" />
          <Route element={<FAQ />} path="faq" />
          <Route path="/blog" element={<Blog />} />
          <Route path="/post/:id/:title" element={<BlogDetail />} />
          <Route path="/req-delete-user-data" element={<RequestforDel />} />

        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
