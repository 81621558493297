import React from "react";
import { NavLink } from "react-router-dom";
import routes from "../utils/data/routes";

function Navbar() {
  return (
    <ul className="navbar">
      {routes.map((route) => (
        <li key={route.id}>
          <NavLink to={route.path} activeClassName="active">
            {route.text}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default Navbar;
