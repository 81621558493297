import React from "react";
import { Zoom } from "react-swift-reveal";

function Hero({ heading, subHeading }) {
  return (
    <section className="heroSection">
      <div className="container">
        <Zoom duration={3000}>
          <div className="heading">{heading}</div>
        </Zoom>
        <Zoom duration={3000} delay={400}>
          <div className="subHeading">{subHeading}</div>
        </Zoom>
      </div>
    </section>
  );
}

export default Hero;
