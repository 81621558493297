import React from "react";
import Hero from "../components/Hero";
import Partners from "../components/Partners";
import BlogSection from "../components/BlogSection";
import BenefitCard from "../components/BenefitCard";
import crypto from "../assets/images/crypto.png";
import { Slide, Zoom } from "react-swift-reveal";
import bitcoin from "../assets/images/bitcoin.png";
import { Link } from "react-router-dom";
import Heading from "../components/Heading";

function About() {
  return (
    <>
      <Hero
        heading="About Us"
        subHeading="We are deeply committed to transforming how investors leverage the ever-evolving realm of cryptocurrencies."
      />
      <section className="aboutUs">
        <div className="container">
          <div className="gridWrapper">
            <div className="leftWrapper">
              <Slide left duration={3000} delay={200}>
                <div className="heading">
                  Empowering Investors Through Crypto Arbitrage Innovation
                </div>
              </Slide>
              <Slide left duration={3000} delay={300}>
                <div className="desc">
                  MyArbit is driven by a strong passion to completely change how
                  investors take advantage of the ever-changing realm of
                  cryptocurrencies. As a prominent investment platform, we
                  combine state-of-the-art technology with expert knowledge to
                  offer unmatched prospects for our esteemed investors.
                </div>
              </Slide>
            </div>
            <div className="rightWrapper">
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/e1B06L-Vlog?si=G1DwlGH1tEv8dGLc"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="circle pinkCircle"></div>

          <div className="content">
            <Zoom duration={3000}>
              <Heading title="Challenges and Risks - Handled by MyArbit" />
            </Zoom>
            <div className="desc">
              While crypto arbitrage isn't without challenges, MyArbit mitigates
              them to maximize your profits. We monitor market conditions and
              execute trades swiftly, ensuring you don't miss out on
              opportunities. Our expert team also navigates transaction fees,
              withdrawal limits, and transfer times to optimize your returns.
            </div>
            <img src={bitcoin} alt="investing platform" className="bitcoin" />
            <div className="circle blueCircle"></div>
            <Zoom duration={3000}>
              <Heading title="Your Portal to Crypto Arbitrage Profits" />
            </Zoom>
            <div className="desc">
              MyArbit makes crypto arbitrage accessible and profitable for you.
              Our platform's goal is to provide you with consistent and robust
              returns in the world of digital assets.
            </div>
            <div className="desc">
              Disclaimer: MyArbit ensures that crypto arbitrage is conducted
              with meticulous attention to detail and risk management. As with
              any investment, please be aware that there are potential risks
              involved. Consult with our team or conduct your own due diligence
              before participating.
            </div>
            <Zoom duration={3000}>
              <div className="subHeading">
                MyArbit, your journey to
                <span className="pink"> unlocking the profit potential</span> of
                crypto arbitrage begins now.
                <span className="blue">Join us</span> in the exciting world of
                cryptocurrency investments today!
              </div>
            </Zoom>
            <Link
              to="https://dashboard.myarbit.com/login"
              className="register"
              target="_blank"
            >
              Register
            </Link>
          </div>
        </div>
      </section>
      <Partners />
      <BenefitCard />
      <BlogSection />
    </>
  );
}

export default About;
