import React, { useState , useEffect} from "react"; 
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import { Slide, Zoom } from "react-swift-reveal";

const tiersData = [
  {
    name: "Tier 01",
    balanceRange: "0 - 500 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "2.5%" },
      { name: "Gold Elite", period: 30, roi: "9%" },
      { name: "Platinum Elite", period: 60, roi: "20%" },
      { name: "Titanium Elite", period: 90, roi: "33%" },
      { name: "Diamond Elite", period: 180, roi: "70%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "2.25% - 2.75%" },
      { name: "Gold Elite", period: 30, roi: "8.10% - 9.90%" },
      { name: "Platinum Elite", period: 60, roi: "18.00% - 22.00%" },
      { name: "Titanium Elite", period: 90, roi: "29.70% - 36.30%" },
      { name: "Diamond Elite", period: 180, roi: "63.00% - 77.00%" },
    ],
  },
  {
    name: "Tier 02",
    balanceRange: "501 - 2000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "2.75%" },
      { name: "Gold Elite", period: 30, roi: "9.9%" },
      { name: "Platinum Elite", period: 60, roi: "22%" },
      { name: "Titanium Elite", period: 90, roi: "36.3%" },
      { name: "Diamond Elite", period: 180, roi: "77%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "2.48% - 3.02%" },
      { name: "Gold Elite", period: 30, roi: "8.91% - 10.89%" },
      { name: "Platinum Elite", period: 60, roi: "19.80% - 24.20%" },
      { name: "Titanium Elite", period: 90, roi: "32.67% - 39.93%" },
      { name: "Diamond Elite", period: 180, roi: "69.30% - 84.70%" },
    ],
  },
  {
    name: "Tier 03",
    balanceRange: "2001 - 5000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3%" },
      { name: "Gold Elite", period: 30, roi: "10.8%" },
      { name: "Platinum Elite", period: 60, roi: "24%" },
      { name: "Titanium Elite", period: 90, roi: "39.6%" },
      { name: "Diamond Elite", period: 180, roi: "84%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "2.70% - 3.30%" },
      { name: "Gold Elite", period: 30, roi: "9.72% - 11.88%" },
      { name: "Platinum Elite", period: 60, roi: "21.60% - 26.40%" },
      { name: "Titanium Elite", period: 90, roi: "35.64% - 43.56%" },
      { name: "Diamond Elite", period: 180, roi: "75.60% - 92.40%" },
    ],
  },
  {
    name: "Tier 04",
    balanceRange: "5001 - 10,000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.25%" },
      { name: "Gold Elite", period: 30, roi: "11.7%" },
      { name: "Platinum Elite", period: 60, roi: "26%" },
      { name: "Titanium Elite", period: 90, roi: "42.9%" },
      { name: "Diamond Elite", period: 180, roi: "91%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "2.92% - 3.58%" },
      { name: "Gold Elite", period: 30, roi: "10.53% - 12.87%" },
      { name: "Platinum Elite", period: 60, roi: "23.40% - 28.60%" },
      { name: "Titanium Elite", period: 90, roi: "38.61% - 47.19%" },
      { name: "Diamond Elite", period: 180, roi: "81.90% - 100.10%" },
    ],
  },
  {
    name: "Tier 05",
    balanceRange: "10,001 - 25,000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.5%" },
      { name: "Gold Elite", period: 30, roi: "12.6%" },
      { name: "Platinum Elite", period: 60, roi: "28%" },
      { name: "Titanium Elite", period: 90, roi: "46.2%" },
      { name: "Diamond Elite", period: 180, roi: "98%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "3.15% - 3.85%" },
      { name: "Gold Elite", period: 30, roi: "11.34% - 13.86%" },
      { name: "Platinum Elite", period: 60, roi: "25.20% - 30.80%" },
      { name: "Titanium Elite", period: 90, roi: "41.58% - 50.82%" },
      { name: "Diamond Elite", period: 180, roi: "88.20% - 107.80%" },
    ],
  },
  {
    name: "Tier 06",
    balanceRange: "25,001 - 50000 USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "3.75%" },
      { name: "Gold Elite", period: 30, roi: "13.5%" },
      { name: "Platinum Elite", period: 60, roi: "30%" },
      { name: "Titanium Elite", period: 90, roi: "49.5%" },
      { name: "Diamond Elite", period: 180, roi: "105%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "3.38% - 4.13%" },
      { name: "Gold Elite", period: 30, roi: "12.15% - 14.85%" },
      { name: "Platinum Elite", period: 60, roi: "27.00% - 33.00%" },
      { name: "Titanium Elite", period: 90, roi: "44.55% - 54.45%" },
      { name: "Diamond Elite", period: 180, roi: "94.50% - 115.50%" },
    ],
  },
  {
    name: "Tier 07",
    balanceRange: "50,000+ USDT",
    packages: [
      { name: "Silver Elite", period: 10, roi: "4%" },
      { name: "Gold Elite", period: 30, roi: "14.4%" },
      { name: "Platinum Elite", period: 60, roi: "32%" },
      { name: "Titanium Elite", period: 90, roi: "52.8%" },
      { name: "Diamond Elite", period: 180, roi: "112%" },
    ],
    packagee: [
      { name: "Silver Elite", period: 10, roi: "3.60% - 4.40%" },
      { name: "Gold Elite", period: 30, roi: "12.96% - 15.84%" },
      { name: "Platinum Elite", period: 60, roi: "28.80% - 35.20%" },
      { name: "Titanium Elite", period: 90, roi: "47.52% - 58.08%" },
      { name: "Diamond Elite", period: 180, roi: "100.80% - 123.20%" },
    ],
  },
];



const isEven = (num) => (num + 1) % 2 !== 0;

const Tier = ({ name, balanceRange, packagesData, index }) => (
  <div key={name} className={`row ${isEven(index) ? "" : "row-reverse"}`}>
    <div className="col-md-5 column left">
      <div className="title">Balance: {balanceRange}</div>
      <Slide up duration={2000} delay={100}>
        <div className="table">
          <div className="tableHead">
            <div>Pool</div>
            <div>Investment Period</div>
            <div>Return on Investment</div>
          </div>
          {packagesData.map((category) => (
            <div key={category.name} className="tableBody">
              <div className="package">{category.name}</div>
              <div>{category.period} Days</div>
              <div>{category.roi}</div>
            </div>
          ))}
        </div>
      </Slide>
    </div>
    <div className="col-md-2">
      <div className="name">{name}</div>
    </div>
    <div className="col-md-5"></div>
  </div>
);

function ProfitTiers() {
  const [activeTab, setActiveTab] = useState("Islamic");
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    // Check if the screen is mobile-sized
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile(); // Check on initial render
    window.addEventListener("resize", checkIsMobile); // Listen for resize

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return (
    <>
      <Hero
        heading="Climbing The Crypto Tiers"
        subHeading="Unlocking Profits on MyArbit Platform"
      />

      <div className="container">
        <div className="profit">
          <div className="circle pinkCircle"></div>
          <Zoom duration={3000} delay={100}>
            <Heading title="Impressive Profit Tiers Structure for Cryptocurrency Ventures" />
          </Zoom>

          <div className="tabset">
            <input
              type="radio"
              name="tabset"
              id="tab1"
              value="Islamic"
              checked={activeTab === "Islamic"}
              onChange={() => setActiveTab("Islamic")}
            />
            <label htmlFor="tab1">Islamic</label>

            <input
              type="radio"
              name="tabset"
              id="tab2"
              value="Normal"
              checked={activeTab === "Normal"}
              onChange={() => setActiveTab("Normal")}
            />
            <label htmlFor="tab2">Normal</label>
          </div>
          <div>
        {activeTab === "Islamic" ? (
          <p className="styled-text">
            The Islamic  offers investment options that are compliant with 
            Sharia law, providing ethical cryptocurrency ventures with guaranteed 
            adherence to Islamic principles.
          </p>
        ) : (
          <p className="styled-text">
            The Normal  provides standard investment options with flexible 
            periods and returns, tailored to regular cryptocurrency traders 
            and investors.
          </p>
        )}
      </div>

          {tiersData.map((tier, index) => (
            <Tier
              key={tier.name}
              name={tier.name}
              balanceRange={tier.balanceRange}
              packagesData={activeTab === "Islamic" ? tier.packagee : tier.packages}
              index={index}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default ProfitTiers;