import { useState, useEffect } from "react";
import Logo from "../components/Logo";
import Navbar from "../components/Navbar";
import LogoDark from "../components/LogoDark";
import { Link, useLocation } from "react-router-dom";
import socialMedia from "../utils/data/socialMedia";

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();

  const handleMenuClick = (e) => {
    e.preventDefault();
    if (e.target.classList.contains("menu") && e.target.closest(".header") && window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        setMenuActive(true);
      }, 250);
    } else {
      setMenuActive(!menuActive);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuActive(false);
  }, [location]);

  return (
    <>
      <div className={`header ${menuActive ? "menuActive" : ""}`}>
        <div className="container">
          <div className="wrapper">
            <Link to="/">
              <Logo />
            </Link>
            <Navbar />
            <div className="btnWrapper">
              <Link to="https://dashboard.myarbit.com/login" target="_blank" className="btn login">
                Login
              </Link>
              <Link to="https://dashboard.myarbit.com/register?signUp=true" target="_blank" className="btn register">
                Register
              </Link>
            </div>
            <div className="menuBtn" onClick={handleMenuClick}></div>
          </div>
        </div>
        <div className="menu">
          <div className="container">
            <div className="wrapper">
              <Link to="/">
                <LogoDark />
              </Link>
              <div className="menuClose" onClick={handleMenuClick}></div>
            </div>
            <Navbar />
            <div className="btnWrapper">
              <Link to="https://dashboard.myarbit.com/login" target="_blank" className="btn login">
                Login
              </Link>
              <Link to="https://dashboard.myarbit.com/page-register?signUp=true" target="_blank" className="btn register">
                Register
              </Link>
            </div>
            <div className="socialMedia">
              {socialMedia.map((e) => (
                <Link to={e.path} target="_blank" key={e.id}>
                  {e.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
