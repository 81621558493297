import React from "react";
import { ReactComponent as LeftBracket } from "../assets/svg/LeftBracket.svg";
import { ReactComponent as RightBracket } from "../assets/svg/RightBracket.svg";
import { ReactComponent as Badge } from "../assets/svg/Badge.svg";
import { Slide } from "react-swift-reveal";

function BenefitCard() {
  return (
    <section className="benefitBottom">
      <div className="circle pinkCircle"></div>
      <div className="container">
        <div className="iconBox">
          <Badge />
        </div>
        <div className="wrapper">
          <Slide left duration={1000}>
            <LeftBracket />
          </Slide>
          <div className="flex-1">
            <div className="heading">As Safe as a bank</div>
            <div className="subHeading">Get profit time to time</div>
            <div className="desc">Your data is never shared. Your funds are always protected with 2FA, SSL encryption, and sophisticated background monitoring.</div>
          </div>
          <Slide right duration={1000}>
            <RightBracket />
          </Slide>
        </div>
      </div>
    </section>
  );
}

export default BenefitCard;
