import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../components/FooterLogo";
import socialMedia from "../utils/data/socialMedia";
import footerImg from "../assets/images/footerImg.png";
import { Fade, Slide } from "react-swift-reveal";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer>
      <div className="footer">
        <marquee>Welcome! it’s time to Take the Leap, it’s MYARBIIT</marquee>
        <div className="wrapper">
          <div className="grid">
            <div className="leftWrapper">
              <img src={footerImg} alt="" />
            </div>
            <div className="rightWrapper">
              <Slide right duration={3000} delay={200}>
                <p className="desc">We look forward to partnering with you on your investment journey. MyArbit is now welcoming new investors globally.</p>
              </Slide>
              <Slide right duration={3000} delay={200}>
                <p className="shortDesc">Open to international investors, we accept new participants to grow with flexible time and money</p>
                <Link to="/contact-us">Contact Us</Link>
              </Slide>
            </div>
          </div>
          <div className="grid-3">
            <div className="card">
              <FooterLogo />
              <Fade duration={3000} delay={200}>
                <p className="about">
                  At MyArbit, we are passionate about revolutionizing the way investors capitalize on the dynamic world of cryptocurrencies. As a leading investing platform, we bring together
                  cutting-edge technology
                </p>
              </Fade>
            </div>
            <div className="card">
              <Fade duration={3000} delay={200}>
                <div className="heading">Get In Touch</div>
              </Fade>
              <div className="socialWrapper">
                {socialMedia.map((e) => (
                  <Link to={e.path} target="_blank" key={e.id}>
                    <img src={e.icon} alt={e.alt} />
                  </Link>
                ))}
              </div>
            </div>
            <div className="card">
              <Fade duration={3000} delay={400}>
                <div className="heading">Location</div>
              </Fade>
              <div className="location">Dubai - UAE</div>
              <div className="address">
              DMCC Plot No. 2-27, Al Thanyah-Jumeirah Lake Towers
              </div>
            </div>
          </div>
          <div className="copyright">{currentYear} © MYARBIT. All rights reserved</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
