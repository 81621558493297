import React from "react";
import { Link } from "react-router-dom";
import useFetchBlogs from "../utils/useFetchBlogs.js";

function Post() {
  const { articles, loading, error } = useFetchBlogs();
  const apiUrl = process.env.REACT_APP_STRAPI_API;

  const featuredPosts = articles
    .filter((article) => article.attributes)
    .slice(-2);

  return (
    <>
      {featuredPosts.map((article) => (
        <div key={article.id} className="blog">
          <img
            src={`${apiUrl}${article.attributes?.featuresImage.data[0].attributes.url}`}
            alt="Featured"
            className="featuredImg"
          />
          <div className="postHead">
            <div className="nameWrapper">
              <img
                src={`${apiUrl}${article.attributes?.authorPic.data.attributes.url}`}
                alt="Author"
              />
              {article.attributes?.author}
            </div>
            {/* <span>{article.attributes?.date}</span> */}
          </div>
          <div className="title">{article.attributes?.Title}</div>
          <div className="shortDesc">{article.attributes?.shortDes}</div>
          <Link to={`/post/${article.id}`} className="link">
            Read More
          </Link>
        </div>
      ))}
    </>
  );
}

export default Post;
