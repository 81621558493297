import Ap from "../../assets/images/1.png";
import Benzinga from "../../assets/images/2.png";
import CryptoNews from "../../assets/images/3.png";
import Yahoo from "../../assets/images/4.png";
import Digital from "../../assets/images/5.png";
import AccessWire from "../../assets/images/6.png";
import BusinessInsider from "../../assets/images/7.png";
import KhaleejTimes from "../../assets/images/8.png";

const partners = [
  {
    id: 1,
    img: KhaleejTimes,
    url: "https://www.khaleejtimes.com/kt-network/myarbit-your-pathway-to-stress-free-cryptocurrency-investing?amp=1",
  },
  {
    id: 2,
    img: Benzinga,
    url: "https://www.benzinga.com/pressreleases/23/12/36158668/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform",
  },
  {
    id: 3,
    img: CryptoNews,
    url: "https://cryptonewsbtc.org/2023/12/09/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform/",
  },
  {
    id: 4,
    img: Yahoo,
    url: "https://finance.yahoo.com/news/myarbit-redefines-cryptocurrency-investing-revolutionary-114500955.html",
  },
  {
    id: 5,
    img: Digital,
    url: "https://www.digitaljournal.com/pr/news/accesswire/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform",
  },
  {
    id: 6,
    img: AccessWire,
    url: "https://www.accesswire.com/814815/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform",
  },
  {
    id: 7,
    img: BusinessInsider,
    url: "https://markets.businessinsider.com/news/stocks/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform-1032887129",
  },
  {
    id: 8,
    img: Ap,
    url: "https://markets.businessinsider.com/news/stocks/myarbit-redefines-cryptocurrency-investing-with-revolutionary-platform-1032887129",
  },
];

export default partners;
