import { useState } from "react";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import { Link } from "react-router-dom";
import axios from "axios";

function PrivacyPolicy() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    } else if (/[a-zA-Z]/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number should only contains digit";
    } else if (!/^\d{11}$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone Number is invalid (should be 11 digits)";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      let formData = {
        name: fullName,
        from: email,
        phone: phoneNumber,
        message: message,
      };

      console.log("hdbsfbsdh", formData);

      axios
        .post("https://my-arbit-558be498ca49.herokuapp.com/api/user/contactUsMail", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("Form submitted successfully");
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setMessage("");
            setFormSubmit(true);
          } else {
            console.error("Error submitting form");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Hero heading="Privacy Policy & Terms and Conditions" subHeading="" />

      <section className="privacyTerms">
        <div className="container">
          <div className="gridWrapper">
            <div className="leftWrapper">
              <Heading title="Privacy Policy" />
              <div className="subHeading">Last Updated: 22 Sep 2023</div>
              <div className="infoBox" style={{ color: "white" }}>
                <p>
                  MyArbit ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use
                  our website, located at www.myarbit.com (the "Website") and our services (collectively, the "Services"). By accessing or using the Website or Services, you consent to the practices
                  described in this Privacy Policy.
                </p>
                <h3>1. Information We Collect</h3>
                <p>
                  <strong>1.1 Personal Information</strong> - We may collect personally identifiable information that you provide to us when using our Services. This may include your name, email
                  address, postal address, phone number, and other information that can be used to identify you.
                </p>
                <p>
                  <strong>1.2 Payment Information</strong> - If you make payments or transactions through the Website, we may collect payment card information, billing address, and other relevant
                  payment details.
                </p>
                <p>
                  <strong>1.3 Log Data</strong> - When you access the Website, our servers may automatically log your device's IP address, browser type, operating system, the pages you visit on the
                  Website, the time and date of your visit, and other similar information.
                </p>

                <h3>2. How We Use Your Information</h3>
                <p>
                  <strong>2.1 To Provide and Improve Services</strong> - We use your information to provide and enhance our Services, including processing transactions, responding to inquiries, and
                  improving the functionality and user experience of the Website.
                </p>
                <p>
                  <strong>2.2 To Communicate with You</strong> - We may use your contact information to send you updates, newsletters, promotional materials, and other communications related to our
                  Services. You can opt out of these communications at any time.
                </p>
                <p>
                  <strong>2.3 For Legal and Security Purposes</strong> - We may use your information to comply with legal obligations, protect our rights and interests, and investigate and prevent
                  fraudulent or illegal activities.
                </p>

                <h3>3. Sharing Your Information</h3>
                <p>
                  <strong>3.1 Service Providers</strong> - We may share your information with third-party service providers who assist us in operating the Website and providing the Services. These
                  service providers are contractually obligated to protect your information.
                </p>
                <p>
                  <strong>3.2 Legal Requirements</strong> - We may disclose your information when required by law, regulation, or legal process, or in response to a subpoena or court order.
                </p>

                <h3>4. Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar tracking technologies to collect information about your use of the Website. You can manage your cookie preferences through your browser settings.</p>

                <h3>5. Security</h3>
                <p>
                  We implement reasonable security measures to protect your information. However, please be aware that no method of transmission over the internet or electronic storage is entirely
                  secure.
                </p>

                <h3>6. Your Choices</h3>
                <p>
                  You have the following choices regarding your information:
                  <ul>
                    <li>
                      You can review, update, or delete your personal information by contacting us at <Link to="mailto:support@myarbit.com">support@myarbit.com</Link>.
                    </li>
                    <li>You can opt out of receiving marketing communications from us by following the unsubscribe instructions in the emails we send.</li>
                    <li>You can disable cookies through your browser settings.</li>
                  </ul>
                </p>

                <h3>7. Children's Privacy</h3>
                <p>Our Services are not directed at individuals under the age of 18. We do not knowingly collect personal information from children.</p>

                <h3>8. Changes to this Privacy Policy</h3>
                <p>
                  We may update this Privacy Policy to reflect changes to our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the "Last
                  Updated" date at the beginning of this policy will be revised accordingly.
                </p>

                <h3>9. Contact Us</h3>
                <p>
                  If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <Link to="mailto:support@myarbit.com">support@myarbit.com</Link>.
                </p>
              </div>
            </div>

            <div className="rightWrapper">
              <Heading title="Terms and Conditions of Use" />
              <div className="subHeading">Last Updated: 22 Sep 2023</div>
              <div className="infoBox" style={{ color: "white" }}>
                <p>
                  These Terms and Conditions of Use ("Terms") govern your use of the MyArbit website ("Website") and the services provided through the Website. By accessing or using the Website, you
                  agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use this Website.
                </p>

                <h3>1. Definitions</h3>
                <p>"We," "us," or "our" refers to MyArbit.</p>
                <p>"You" or "user" refers to anyone who accesses or uses the Website.</p>
                <p>"Services" refers to all features, functionalities, and content available through the Website.</p>

                <h3>2. Use of the Website</h3>
                <p>
                  <strong>2.1 Eligibility</strong> - You must be at least 18 years old to use this Website. By using this Website, you represent and warrant that you are at least 18 years of age.
                </p>
                <p>
                  <strong>2.2 Account</strong> - Some areas of the Website may require you to create an account. You are responsible for maintaining the confidentiality of your account information,
                  and you agree to accept responsibility for all activities that occur under your account.
                </p>
                <p>
                  <strong>2.3 Accuracy of Information</strong> - You agree to provide accurate and complete information when using the Website, and you agree to update your information to keep it
                  accurate and current.
                </p>

                <h3>3. Privacy</h3>
                <p>
                  Your use of the Website is also governed by our Privacy Policy, which is incorporated by reference into these Terms. Please review our Privacy Policy for information on how we
                  collect, use, and disclose your personal information.
                </p>

                <h3>4. Intellectual Property</h3>
                <p>
                  <strong>4.1 Ownership</strong> - All content on this Website, including text, graphics, logos, images, and software, is the property of MyArbit or its licensors and is protected by
                  copyright and other intellectual property laws.
                </p>
                <p>
                  <strong>4.2 License</strong> - You are granted a limited, non-exclusive, and non-transferable license to access and use the Website and its content solely for personal and
                  non-commercial purposes. This license does not permit you to modify, reproduce, distribute, or create derivative works of the Website or its content without our prior written
                  consent.
                </p>

                <h3>5. User Conduct</h3>
                <p>
                  <strong>5.1 Prohibited Activities</strong> - When using the Website, you agree not to:
                  <ul>
                    <li>Violate any applicable laws, regulations, or third-party rights.</li>
                    <li>Use the Website for any illegal or unauthorized purpose.</li>
                    <li>Engage in any activity that could damage, disable, overburden, or impair the Website.</li>
                    <li>Attempt to gain unauthorized access to the Website, user accounts, or computer systems connected to the Website.</li>
                    <li>Transmit or upload any malicious code or viruses.</li>
                  </ul>
                </p>
                <p>
                  <strong>5.2 User Content</strong> - If you submit or post any content on the Website, you retain ownership of your content, but you grant us a non-exclusive, worldwide, royalty-free
                  license to use, reproduce, modify, and distribute your content for the purpose of operating and improving the Website.
                </p>

                <h3>6. Termination and Verification</h3>
                <p>
                  MyArbit retains the exclusive right to temporarily suspend your account and request additional personal information for the purpose of verification. This measure is implemented to
                  ensure the security and integrity of our crypto investment platform and to comply with regulatory standards. We appreciate your understanding and cooperation in facilitating a secure
                  and transparent investment environment.
                </p>

                <h3>7. Warranty and Responsibility</h3>
                <p>
                  <strong>7.1 Full Warranty</strong> - The Website and its content are provided with full warranties of any kind, either express or implied, including, but not limited to, the implied
                  warranties of merchantability, fitness for a particular purpose, or non-infringement.
                </p>
                <p>
                  <strong>7.2 Use Without Risk</strong> - Your use of the Website is at your own risk, and you agree that MyArbit is not responsible for any damages resulting from your use of the
                  website, including direct, indirect, incidental, or consequential damages.
                </p>
                <h3>8. Governing Law and Jurisdiction</h3>
                <p>
                  These Terms are governed by the laws of the State of California, without regard to its conflict of laws principles. You agree to submit to the exclusive jurisdiction of the state and
                  federal courts located in San Francisco County, California, for any disputes arising out of or relating to these Terms or your use of the Website.
                </p>

                <h3>9. Changes to these Terms</h3>
                <p>
                  We may update these Terms to reflect changes to our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the "Last Updated"
                  date at the beginning of these Terms will be revised accordingly.
                </p>

                <h3>10. Contact Us</h3>
                <p>
                  If you have any questions or concerns about these Terms or your use of the Website, please contact us at <Link to="mailto:support@myarbit.com">support@myarbit.com</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
