import { useState, useEffect } from "react";

const useFetchBlogs = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = process.env.REACT_APP_TOKEN;
  const apiUrl = process.env.REACT_APP_STRAPI_API;
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await fetch(`${apiUrl}/api/blogs?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        // Log the entire response
        console.log("Fetched blog data fetchblog:", data);

        if (data?.data) {
          setArticles(data.data);
        } else {
          throw new Error("Invalid data format received");
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching blogs:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [apiUrl, token]);

  return { articles, loading, error };
};

export default useFetchBlogs;
