import React from "react";
import { Fade } from "react-swift-reveal";

function WorkCard({ number, bgColor, color, title, desc }) {
  return (
    <>
      <div
        className={`card card-${number}`}
        style={{ backgroundColor: bgColor }}
      >
        <Fade duration={3000} delay={100}>
          <div className="number" style={{ color: color, borderColor: color }}>
            {number}
          </div>
        </Fade>

        <Fade duration={3000} delay={300}>
          <div className="title" style={{ color: color }}>
            {title}
          </div>
        </Fade>
        <Fade duration={3000} delay={400}>
          <div className="desc">{desc}</div>
        </Fade>
      </div>
    </>
  );
}

export default WorkCard;
