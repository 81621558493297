import React from "react";
import Post from "../components/Post";
import { ReactComponent as RightArrow } from "../assets/svg/RightArrow.svg";
import { Link ,useNavigate  } from "react-router-dom";

function BlogSection() {
  const navigate = useNavigate();

  const handleSeeMoreClick = () => {
    navigate('/blog');
  };
  return (
    <section className="blogs">
      <div className="container">
        <div className="circle blueCircle"></div>
        <div className="heading">
          News and Insights
          <div className="icon">
            <Link to="/blog">
              <RightArrow />
            </Link>
          </div>
        </div>
        <div className="subHeading">Knowledge Highlights</div>
        <div className="desc">
          Stay informed and empowered with the latest news and insights in the
          rapidly evolving world of cryptocurrency through our dedicated News &
          Insights section – your go-to resource for staying ahead in the crypto
          landscape
        </div>
        <div className="gridWrapper">
            <Post  />
        </div>
      </div>
      <div className="see-more-container">
        <button className="see-more" onClick={handleSeeMoreClick}>See More</button>
      </div>
    </section>
  );
}

export default BlogSection;